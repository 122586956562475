export const seo = {
  url: 'client',
  title: {
    pl: 'Klient',
    en: 'Client',
    ua: 'Клієнт',
  },
  desc: {
    pl: 'Dla ułatwienia obsługi zebraliśmy w jednym miejscu informacje i materiały przydatne we współpracy z Omida Sea And Air S.A.',
    en: 'For ease of use, we have gathered information and materials useful in cooperation with Omida Sea And Air S.A. in one place.',
    ua: 'Для зручності користування ми зібрали в одному місці інформацію та матеріали, корисні у співпраці з Omida Sea And Air S.A.',
  },
  keywords: ['omida', 'sea and air', 'sea & air', 'klient'],
}

export const intro = {
  title: {
    pl: 'Klient',
    en: 'Client',
    ua: 'Клієнт',
  },
  desc: {
    pl: 'Dla ułatwienia obsługi zebraliśmy w jednym miejscu informacje i materiały przydatne we współpracy z Omida Sea And Air S.A.',
    en: 'For ease of use, we have gathered information and materials useful in cooperation with Omida Sea And Air S.A. in one place.',
    ua: 'Для зручності користування ми зібрали в одному місці інформацію та матеріали, корисні у співпраці з Omida Sea And Air S.A.',
  },
}

export const main = {
  title: {
    pl: 'Strefa Klienta.<br/>Współpraca, informacje, FAQ',
    en: 'Client zone.<br/>Collaboration, information',
    ua: 'Клієнтська зона.<br/>Співпраця, інформація',
  },
  desc: {
    pl: 'W tej sekcji znajdziesz: wymiary kontenerów, rozmieszczenie palet, korektę paliwową, przydatne dokumenty oraz najczęściej zadawane pytania.',
    en: 'In this section you will find: container dimensions, pallet positioning, useful documents.',
    ua: 'У цьому розділі ви знайдете: розміри контейнерів, розташування піддонів, коригування палива, корисні документи та запитання, що часто задаються.',
  },
}
