import React from 'react'
import {
  sSection,
  sTextGradient,
  sText,
  sSubtitle,
  sDesc,
  s,
  colors,
  alpha,
  sHtml,
} from '../style'
import H from './H'

const Main = ({
  h,
  s,
  title,
  subtitle,
  body,
  article,
  desc,
  children,
  extraCssTitle,
  maxWidth,
}) => {
  return (
    <section css={[sSection, maxWidth && { maxWidth }]}>
      <div css={sLine} />
      {subtitle && <span css={sSubtitle}>{subtitle}</span>}
      <H
        h={h}
        css={[sTextGradient, sTitle, sText[s || h || 2], extraCssTitle]}
        html={title}
      />
      {body && (
        <div dangerouslySetInnerHTML={{ __html: body }} css={[sDesc, sBody]} />
      )}
      {article && (
        <article dangerouslySetInnerHTML={{ __html: article }} css={sHtml} />
      )}
      {desc && <p css={[sDesc, sDescMargin]}>{desc}</p>}
      {children}
    </section>
  )
}

const sDescMargin = {
  marginBottom: '4rem',
  [s.sm_down]: {
    marginBottom: '3rem',
  },
}

const sTitle = {
  [s.lg]: {
    maxWidth: '75%',
  },
}

const sBody = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '4rem',
  rowGap: '4rem',
  [s.sm_down]: {
    gridTemplateColumns: '1fr',
    rowGap: '2rem',
    columnGap: '2rem',
  },
  a: {
    textDecoration: 'underline',
    color: colors.brandDark,
  },
}

const sLine = {
  position: 'absolute',
  top: 0,
  left: -30,
  height: '100%',
  width: 10,
  background: `linear-gradient(to bottom, ${
    colors.brandLight
  }, ${colors.brandDark.concat(alpha[0])})`,
  borderRadius: 5,
  [s.sm_down]: {
    top: -60,
    left: 0,
    width: '100%',
    height: 4,
    background: `linear-gradient(to right, ${
      colors.brandLight
    }, ${colors.brandDark.concat(alpha[0])})`,
  },
  [s.lg]: {
    left: -50,
  },
  [s.xl]: {
    left: -60,
  },
}

export default Main
